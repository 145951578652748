<template>
  <div class="">
    <h1 class="py-5 fw-semibold heading mtext">KEY FACTS ABOUT OUR SCHOOL</h1>
    <div style="position:relative">
      <div class="row gx-0 gy-0">
        <div class="col-3">
          <div class="row gx-0 gy-0">
            <div class="col-12">
              <div class="m-1 box">
                <h5 style="color:purple" class="title">LANGUAGES TAUGHT</h5>
                <p>Mandarin,Japanese</p>
              </div>
            </div>
          </div>
          <div class="row gx-0 gy-0">
            <div class="col-12">
              <div class="m-1 box">
                <h4 style="color:goldenrod" class="title">AFFORDABLE <BR /> SCHOOL FEES</h4>
                <img src="@/assets/img/money.png" style="width:100px;height: 100px;" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row gx-0 gy-0">
            <div class="col-5">
              <div class="m-1 box">
                <h5 style="color:purple" class="title">CURRICULUM </h5>
                <div class="row gx-0 ">
                  <div class="col-6 pb-1">
                    <img data-bs-toggle="tooltip" data-bs-placement="top" v-tippy="'Myanmar'"
                      data-bs-custom-class="custom-tooltip" data-bs-title="Myanmar" src="@/assets/img/myanmarflag.png"
                      style="width:90%;" alt="">
                  </div>
                  <div class="col-6 pb-1">
                    <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                      data-bs-title="Singapore" v-tippy="'Singapore'" src="@/assets/img/sgflag.png" style="width:90%;"
                      alt="">
                  </div>
                  <div class="col-6 p-1">
                    <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                      data-bs-title="United Kingdom" v-tippy="'United Kingdom'" src="@/assets/img/ukflag.png"
                      style="width:90%;" alt="">
                  </div>
                  <div class="col-6 p-1">
                    <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                      data-bs-title="United State " v-tippy="'United State '" src="@/assets/img/usflag.png"
                      style="width:90%;" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="m-1 box ratio-box">
                <img src="@/assets/img/groupteaching.png" style="width:50%;height: 80px;" alt="">
                <h5 style="color: purple;" class="title">STUDENT TO <BR /> TEACHER RATIO</h5>
                <h2>7:1</h2>
              </div>
            </div>
          </div>
          <div class="row gx-0 gy-0">
            <div class="col-12">
              <div class="m-1 box">
                <h4 style="color:purple" class="title">LANGUAGE OF INSTRUCTION</h4>
                <p style="color:purple" class="text-center description">English</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row gx-0 gy-0">
            <div class="col-12">
              <div class="m-1 box">
                <div class="row ">
                  <div class="col-8  ">
                    <h5 class="mt-3 title" style="color:goldenrod"> AGE RANGE</h5>
                    <h2 style="font-size:50px;font-weight:900; color:purple">3-18</h2>
                  </div>
                  <div class="col-4 ">
                    <img src="@/assets/img/takegradu.png" style="width:100%;height: 100px;" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row gx-0 gy-0 ">
            <div class="col-12 ">
              <div class="m-1 box">
                <H5 CLASS="mb-3 title" style="color:purple">DIGITAL LITERACY </h5>
                <div class="row gx-0">
                  <div class="col-4 ">
                    <img src="@/assets/img/usingtech.png" style="width:100%;" alt="">
                  </div>
                  <div class="col-4">
                    <img src="@/assets/img/monitor.png" style="width:100%;" alt="">
                  </div>
                  <div class="col-4">
                    <img src="@/assets/img/touchtablet.png" style="width:100%;" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gx-0 gy-0">
        <div class="col-4">
          <div class="m-1 curricular box">
            <h5 style="color:purple" class="title">CO-CURRICULAR ACTIVITIES</h5>
            <div class="flex flex-column">
              <img v-tippy="'Arts and Craft!'" data-bs-toggle="tooltip" data-bs-placement="top" title="Arts and Craft"
                alt="hello" src="@/assets/img/painting.png" class="icon">
              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="Dance" v-tippy="'Dance'" src="@/assets/img/dancing.png"
                class="icon" alt="">
              <img v-tippy="'Life Skills'" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-custom-class="custom-tooltip" data-bs-title="Life Skills" src="@/assets/img/shouting.png"
                class="icon" alt="">
              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="Music " v-tippy="'Music'" src="@/assets/img/singing.png"
                class="icon" alt="">
              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="Yoga" v-tippy="'Yoga'" src="@/assets/img/sitting.png"
                class="icon" alt="">
                <br>
              <img data-bs-toggle="tooltip" class="steam-icon" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="STEAM" v-tippy="'STEAM'" src="@/assets/img/steamlet.png"
                alt="">
              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="Mindfulness" v-tippy="'Mindfulness'" src="@/assets/img/brainstorming.png"
                class="icon" alt="">
              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                data-bs-title="Physical Education" v-tippy="'Physical Education'" src="@/assets/img/running.png"
                class="icon" alt="">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="m-1 box">
            <h5 style="color:purple" class="title">INTERNATIONAL <br> OLYMPIAD EXAM CENTER</h5>
            <div>
              <img src="@/assets/img/sitandwriting.png" style="width:100px;height: 100px;" alt="">
              <img src="@/assets/img/sitandwriting.png" style="width:100px;height: 100px;" alt="">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="m-1 box">
            <h5 style="color:purple" class="title">GENERATIONS OF <BR /> EXCELLENCE </h5>
            <img src="@/./assets/img/badge.png" style="width:100px;height: 100px;" alt="">
          </div>
        </div>
      </div>
      <div style="top:-225px;right:-20px; position:absolute">
        <img src="@/assets/img/sit_home.png" class="elephant" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type
    }
  }
}
</script>

<style lang="scss" scoped>
.curricular {
  .icon {
    width:35px;
    height: 35px;
    margin:5px
  }

  .steam-icon {
    width: 85px;
    height: 40px;
  }
}

.box {
  border:1px black;
  border-style:solid;
  border-radius:20px;
  padding:20px;
  height:210px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  margin: 5px 0 10px 0;
  font-weight: var(--cus-fw-semibold);
  word-wrap: break-word;
}

.ratio-box {
  img {
    width: 50px;
    height: 50px; 
  }
  h2 {
    color:goldenrod;
    font-size: 35px;
    font-weight: 800;
  }
}

.description {
  font-size: 18px;
}

.heading {
  margin: 0 130px 0 0;
  display: inline-block;
}

.elephant {
  width:280px;
  height:280px;
}

// custom media
@media (min-width: 320px) {
  .title {
    margin-bottom: 15px;
    font-size: 25px;
  }

  .description {
    font-size: 20px;
  }

  .box {
    height: 270px;
  }

  .curricular {
  .icon {
    width:45px;
    height: 45px;
    margin:5px
  }

  .steam-icon {
    width: 90px;
    height: 40px;
  }
}
}

@media (min-width: 320px) {
  .ratio-box {
  img {
    width: 50px;
    height: 50px; 
  }
  h2 {
    color:goldenrod;
    font-size: 40px;
    font-weight: 800;
  }
}
}

@media (min-width: 1280px) {
  .heading {
    margin-right: 0;
  }
}
</style>