<template>
  <div class="card_container">
    <div class="card cus_card">
      <div class="card-body p-1 p-lg-5">
        <div class="content_container shadow">
          <h1 class="text-uppercase">ကျောင်းအပ်လက်ခံရေးအစီအစဉ်</h1>
          <p class="content">
            ရွှေ မော်ကွန်းကျောင်းတွင် ကျောင်းသားဦးရေ ကန့် သတ်ထားမှုကြောင့် လက်ရှိ တက်ရောက်နေသော ကျောင်းသား/သူများ အနက်
            ကျောင်းထွက်သူရှိမှ Weekend နှင့် Summer Programme တွင် တက်ရောက်နေသော ကျောင်းသား/သူများ ကို
            ပထမဦးစားပေးအနေဖြင့်လည်းကောင်း၊ ပြင်ပကျောင်းသား/သူကိုလည်း ဝင်ခွင့်စာမေးပွဲ ဖြေဆိုအောင်မြင်ပြီး ကျောင်း၏
            လုပ်ငန်းဆောင်တာ
            များတွင် ပူးပေါင်းပါဝင်နိုင်မှု၊ အကောင်းမြင်ဝါဒရှိမှု နှင့် မိဘ ပူးပေါင်း ဆောင်ရွက်နိုင်မှုအပေါ် မူတည်ပြီး
            ကျောင်းသားသစ်ကို ကျောင်းအပ်လက်ခံမှု ပြုလုပ်သည်။
          </p>
          <p class="content">
            အသက် (၃)နှစ်ပြည့်ပြီး မူလတန်းကြိုတက်ရောက်မည့် ကျောင်းသား/ကျောင်းသူများ အတွက်လည်း ရွှေ မော်ကွန်းကျောင်းတွင်
            လက်ရှိ တက်ရောက်နေသော ကျောင်းသား/သူများ၏ မွေးချင်း မောင်နှမများကို အဓိက ဦးစားပေး လက်ခံပါသည်။
            ကျောင်းသား/ကျောင်းသူများကို အကန့် အသတ်နှင့်သာ လက်ခံမည် ဖြစ်သည့် အတွက် ပြင်ပ ကျောင်းသား/ကျောင်းသူများ ၏
            Waitlist ကို စီစစ်အကဲဖြတ်မှု ၊အကောင်းမြင်ဝါဒနှင့် မိဘ ပူးပေါင်း ဆောင်ရွက်မှုပေါ် မူတည်ပြီး ကျောင်းအပ် လက်ခံမှု
            ပြုလုပ်သည်။
          </p>
        </div>
        <div class="image_contianer">
          <div class="image" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.cus_card {
  width: 100%;
  background-color: var(--cus-primary);
  border-radius: 0 !important;
}

.content_container {
  position: static;
  z-index: 20;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--cus-secondary);
  margin-bottom: 1.5rem;

  @include breakpoint(large) {
    width: 55%;
    position: relative;
    padding: 30px 30px;
    margin-bottom: 0;
  }

  h1 {
    font-weight: bolder;
    margin-bottom: 30px;
  }

  p {
    text-align: justify;
  }
}

.image {
  background-image: url('@/assets/img/photo/03.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: static;
  width: auto;
  height: 240px;

  @include breakpoint(medium) {
    height: 400px;
  }

  @include breakpoint(large) {
    background-position: 70% center;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    right: 48px;
    position: absolute;
    padding: 30px 30px;
    width: 550px;
    height: 550px;
  }
}

img {
  z-index: 5;
}

.content {
  line-height: 30px;
  text-indent: 30px;
}
</style>