<template>
  <h2 class="mtext text-center">
    OUR SCHOOLS</h2>
  <div class="row gx-0" style="background:#663399">
    <div class="col-12 col-md-6 col-xl-3">
      <div class="school_container">
        <!-- <img src="@/assets/img/1.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-1">
        </div>
        <div v-if="font_type == 1" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2"> PRE-SCHOOL</p>
          <p class="our-school-text">Age 3-5</p>
        </div>
        <div v-if="font_type == 2" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">မူလတန်းကြိုကျောင်း</p>
          <p class="our-school-text">အသက် ၃ - ၅</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-3">
      <div class="school_container">
        <!-- <img src="@/assets/img/4.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-4">
          </div>
        <div v-if="font_type == 1" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">PRIMARY</p>
          <p class="our-school-text">Age 5-12</p>
        </div>
        <div v-if="font_type == 2" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">မူလတန်း</p>
          <p class="our-school-text">အသက် ၅ - ၁၂</p>
        </div>
      </div>
    </div>

    <!--    <div class="col d-flex justify-content-center">-->
    <!--      <div class="school_container" style="min-width:250px;max-width: 400px;">-->
    <!--          <img src="@/assets/img/2.jpg" style="width:100%;height:100%;" alt="">-->
    <!--        <div class="our_school_div_letter">-->
    <!--            <p class="our-school-title mb-2"> LOWER SECONDARY</p>-->
    <!--            <p>Age 12-14</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col-12 col-md-6 col-xl-3">
      <div class="school_container">
        <!-- <img src="@/assets/img/2.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-2">
          </div>
        <div v-if="font_type == 1" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2"> LOWER SECONDARY</p>
          <p class="our-school-text">Age 12-14</p>
        </div>
        <div v-if="font_type == 2" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">အလယ်တန်း</p>
          <p class="our-school-text">အသက် ၁၂ - ၁၄</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-3">
      <div class="school_container">
        <!-- <img src="@/assets/img/3.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-3">
        </div>
        <div v-if="font_type == 1" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">WEEKEND & <br> SUMMER PROGRAM</p>
          <p class="our-school-text">Age 3-18</p>
        </div>
        <div v-if="font_type == 2" style="position:absolute;bottom: 0;right: 0;left: 0;">
          <p class="our-school-title mb-2">စနေ/တနင်္ဂနွေ နှင့် <br> နွေရာသီ သင်တန်း</p>
          <p class="our-school-text">အသက် ၃ - ၁၈ </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type
    }
  }
}
</script>

<style lang="scss" scoped>
.school_container {
  // margin-left: 5px;
  // margin-right: 5px;
  position: relative;
}

.our_school_div_letter {
  bottom: 0;
  color: white;
  left: 50%;
  transform: translate(-50%, -0%);
  position: absolute;
}

.school-img {
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.img-1 {
  background-image: url('@/assets/img/1.jpg');
}

.img-2 {
  background-image: url('@/assets/img/2.jpg');
}
.img-3 {
  background-image: url('@/assets/img/3.jpg');
}
.img-4 {
  background-image: url('@/assets/img/4.jpg');
}
</style>