<template>
  <div class="">
    <div class=" Part1 ">
      <div class="slider_school">
        <div id="carouselExampleCaptions" class="carousel slide slider_inside" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
              aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
              aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner mb-3">
            <div class="carousel-item active" data-bs-interval="2000">
              <img src="@/assets/img/home/slider/pre.jpg" class="d-block w-100 slider_inside" alt="...">
              <div class="slider_div_text d-flex" style="background:purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Pre-School</h5>
                    </div>
                  </div>
                  <div class="">
                    <div>
                    <h5 class="t_shadow pt-3 content-title d-md-none">Pre-School</h5>  
                      <p class="t_shadow p-3 content-text" style="text-align:justify;  word-wrap: break-word;">
                        သင်ကြားရေးနှင့် ပတ်သက်ပြီး ပြည်ထောင်စု မြန်မာနိုင်ငံတော် လူမှုဝန်ထမ်းဦးစီးဌာနမှ ပြဌာန်းထားသော
                        ECCD
                        သင်ရိုးညွှန်းတမ်းနှင့် နိုင်ငံတကာ သင်ရိုးညွှန်းတမ်း စာအုပ်များကို ရွေးချယ် အသုံးပြုပါသည်။
                        ကလေးများ၏ ဘဝတွင် မရှိမဖြစ် လိုအပ်သည့် Life Skills ၊ Mindfulness ၊ Montessori ၊ STEAM Education
                        ၊
                        Character Education ၊ ၃၈ ဖြာ မင်္ဂလာကဗျာများ၊ ပုံပြင်များအပြင် Digital learning Platform
                        များကိုလည်း အသုံးပြု၍ သင်ကြားပေးနေပါသည်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src="@/assets/img/home/slider/primary.png" class="d-block w-100 slider_inside" alt="...">
              <div class=" slider_div_text d-flex" style="background:purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Primary School</h5>
                    </div>
                  </div>
                  <div class="">
                    <div>
                    <h5 class="t_shadow pt-3 content-title d-md-none">Primary School</h5> 
                      <p class="t_shadow p-3 content-text" style="text-align:justify;">
                        ရွှေမော်ကွန်း ကိုယ်ပိုင်မူလတန်းကျောင်းရှိ ကျောင်းသား/သူများ ငယ်ရွယ်စဉ်အချိန်တွင် Japanese ၊
                        Chinese အစရှိသည့် နိုင်ငံခြားဘာသာစကားတစ်ခုခုကို ထပ်ဆောင်းအနေဖြင့် ရွေးချယ်သင်ကြားခြင်းဖြင့်
                        ဘာသာစကားအခြေခံကောင်းများ ငယ်စဉ်အရွယ်မှာ လျင်မြန်စွာရရှိမည့်အပြင် နိုင်ငံတကာ၏ယဉ်ကျေးမှုနှင့်
                        Knowledge is Power ဆိုသည့်အတိုင်း ပြင်ပဗဟုသုတများ တိုးပွားလာစေသည့် အကျိုးကျေးဇူးများရရှိမည်
                        ဖြစ်ပါသည်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src="@/assets/img/home/slider/sec.jpg" class="d-block w-100 slider_inside" alt="...">
              <div class="slider_div_text d-flex" style="background:purple">
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-start">
                  <div class="content-title-container d-none d-md-block">
                    <div>
                      <h5 class="t_shadow p-3 content-title">Secondary School</h5>
                    </div>
                  </div>
                  <div class="">
                    <div>
                    <h5 class="t_shadow p-3 content-title d-md-none">Secondary School</h5>
                      <p class="t_shadow p-3 content-text" style="text-align:justify">
                        ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင်် အခြေခံပညာသင်ရိုးနှင့် နိုင်ငံခြားသင်ရိုးတို့ကို
                        ပူးတွဲသင်ကြားပေးရုံသာမက ပြင်ပသင်ရိုးများအနေဖြင့်
                        Mindfulness ၊ Arts ၊ Physical Education ၊ Music Education ဦ Coding နှင့် STEM တို့ကိုပါ
                        ထည့်သွင်းသင်ကြားပေးခြင်းကြောင့်
                        ကျောင်းသား/သူများ ၏ ကိုယ်ကာယ ကျန်းမာသန်စွမ်းမှု၊ အာရုံစူးစိုက်မှုများ၊ ဖန်တီးမှုစွမ်းရည်၊
                        အနုပညာစွမ်းရည် တို့ကို ဖွံ့ဖြိုး တိုးတက်စေနိုင်ပါသည်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.carousel {
  img {
    height: 300px;

    @include breakpoint(medium) {
      height: 600px;
    }

    @include breakpoint(large) {
      height: 700px;
    }
  }
}

.content-title-container {
  min-width: 300px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.content-title {
  font-size: var(--cus-fs-xx-large);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-xxx-large);
  }
}

.content-text {
  min-width: 300px;
  margin: 0;

  font-size: var(--cus-fs-normal);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-large);
  }
}

.slider_div_text {
  height: 420px;
  position: relative;
  overflow: hidden;

  @include breakpoint(xsmall) {
    height: 340px;
  }

  @include breakpoint(small) {
    height: 270px;
  }

   @include breakpoint(medium) {
    height: 340px;
  }

  @include breakpoint(large) {
    height: 240px;
  }
}
</style>