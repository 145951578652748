<template>
  <div class="text-start mb-5">
    <div class="container push-up-6">
      <section>
        <h1 class="title text-uppercase mb-5">frequently asked question</h1>
        <div class="list-box">
          <div class="list-title p-4">မိဘများ အမေးအများဆုံး မေးခွန်း (၇) ခု (FAQ)</div>
          <div class="lists">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    ၁။ ကလေးအသက် ဘယ်အရွယ်လောက်ကနေ ကျောင်းအပ်စလက်ခံပါသလဲ။
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ရွှေ မော်ကွန်းအဆင့် မြင့် မူကြိုကျောင်းမှာ အသက် (၃) နှစ်မှစပြီး ကလေးများအား ကြိုတင်ပေးထားသော
                    အမည်စာရင်းများမှ အတန်းတစ်တန်းနှင့် တစ်ခန်းချင်းစီအတွက် သတ်မှတ်ထားသော ကျောင်းသားဦးရေအတိုင်း Assessment/
                    Placement Test စစ်ပြီး လက်ခံပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    ၂။ ကျောင်းဝင်ခွင့် အတွက် Assessment / Placement Test က ဘယ်အချက်တွေကို အဓိကစစ်ဆေးတာပါလဲ။
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Pre-School အတွက် Assessment ကတော့ ဖွံ့ဖြိုးမှုနယ်ပယ် (၅) ရပ်ကို အဓိကစစ်ဆေးပါတယ်။
                    <br>
                    ဖွံ့ဖြိုးမှုနယ်ပယ် (၅) ရပ်ကတော့<br>
                    > စာရိတ္တပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု (စိတ်လှုပ်ရှားမှုဆိုင်ရာဖွံ့ဖြိုးမှု)<br>
                    > ကာယပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br>
                    > ဉာဏ်ရည်ပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br>
                    ဘာသာစကားပိုင်းဆိုင်ရာဖွံ့ဖြိုးမှု<br>
                    ပေါင်းသင်းဆက်ဆံရေးပိုင်းဆိုင်ရာဖွံဖြိုးမှု များပေါ်အခြေခံပြီးစစ်ဆေးပါတယ်။<br>
                    <br>
                    Primary & Secondary Assessment/ Placement Test ကတော့ Pearson Edexcel (UK) International သင်ရိုးတို့ရဲ့
                    အဓိကဘာသာရပ်များဖြစ်တဲ့ English, Maths & Science ဘာသာရပ်များကို စစ်ဆေးပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    ၃။ ရွှေမော်ကွန်းကျောင်းမှာ ဘယ်အတန်းအထိ ဖွင့်လှစ်သင်ကြားနေပါသလဲ။
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ရွှေ မော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ မူကြိုအရွယ်မှစပြီး မြန်မာသင်ရိုး
                    သတ္တမတန်း ၊ Pearson Edexcel (UK) နှင့် International သင်ရိုးတို့ကို ပူးတွဲပြီး Year-8 အထိ ၂၀၂၂-၂၀၂၃
                    ပညာသင်နှစ်မှာ ဖွင့်လှစ် သင်ကြားလျက်ရှိပြီး ပညာသင်နှစ်တစ်နှစ်တိုင်းတွင် တစ်နှစ်တစ်တန်းတိုးကာ
                    အထက်တန်း အထိ တိုးချဲ့ဖွင့် လှစ်သင်ကြားပေးသွားမည်ဖြစ်ပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    ၄။ ရွှေ မော်ကွန်းကျောင်းမှာ သင်ရိုး (၂) မျိုးကိုဘယ်လိုသင်ကြားပေးနေပါသလဲ။
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ရွှေ မော်ကွန်းကျောင်းကဆိုရင် နိုင်ငံတကာပညာရေးကိုလက်လှမ်းမှီအောင် မြန်မာသင်ရိုး နဲ့ International
                    သင်ရိုးနှစ်ခုကို ပူးတွဲပြီး သင့်တင့်မျှတသော ကျောင်းလခဖြင့် သင်ကြားပေးနေပါတယ်ရှင့်။
                    ပညာရေး၀န်ကြီးဌာနပြဌာန်းသင်ရိုး အနေနဲ့ဆိုရင်တောင်ကြီးမြို့၏ အထင်ကရကျောင်းတစ်ကျောင်းဖြစ်သော အ.ထ.က (၄)
                    တောင်ကြီးကျောင်းနဲ့ ချိတ်ဆက်ထားပေးပါတယ်။ Shwe Maw Kun Education Group ကဆိုရင် Pearson Edexcel (UK)
                    Cambridge English Exam Preparation တို့ ရဲ့အသိအမှတ်ပြု Centre ရထားတဲ့ကျောင်းဖြစ်သည့်အပြင် British
                    Council ၏ Partner School လည်းဖြစ်ပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    ၅။ သင်ရိုး (၂) မျိုးသင်ကြားပေးတော့ ကျောင်းသား/သူ တွေအတွက် ဘာအကျိုးကျေးဇူးများရှိပါသလဲ။
                  </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ပညာရေး၀န်ကြီးဌာနပြဌာန်းသင်ရိုးသင်သည့်မည်သည့်ကျောင်းမဆို ရွှေ့ ပြောင်းနိုင်ပြီး
                    နိုင်ငံခြားသင်ရိုးသင်ကြားသော ပြည်တွင်းရှိ International School အပြင် ပြည်ပရှိ ကျောင်းများသို့ ရွှေ့
                    ပြောင်းမည်ဆိုပါကလည်း Pearson Edexcel (UK) ၏ Primary (Year 6) ၊ Lower Secondary (Year 9) နှင့် IGCSE
                    အစရှိသော နိုင်ငံတကာ အသိအမှတ်ပြုအောင်လက်မှတ်များကို စာမေးပွဲများဖြေဆိုအောင်မြင်သူ ကျောင်းသား/ သူ
                    အတိုင်း Pearson Edexcel (UK) မှ ချီး မြှင့် မည် ဖြစ်သောကြောင့် ထိုလက်မှတ်များဖြင့် ကျောင်းဝင်ခွင့်
                    လျှောက်ထားနိုင်ပါတယ်။
                    မှတ်ချက်။ ။ ကျောင်းဝင်ခွင့်မှာ နိုင်ငံအလိုက် ၊ ကျောင်းတစ်ကျောင်းချင်းစီပါ်မူတည်ပြီး
                    ကွာခြားမှုရှိနိုင်ပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    ၆။ စာသင်ခန်းတစ်ခန်းမှာ ကျောင်းသား/သူဦးရေ အများဆုံး ဘယ်နှစ်ယောက်ထားပါသလဲ။
                  </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    စာသင်ခန်းတစ်ခန်းမှာဆိုရင် အခန်းအကျယ်အဝန်းပေါ်မူတည်ပြီး ကျောင်းသားဦးရေ (၁၅ - ၂၅) သာ အများဆုံး
                    လက်ခံသင်ကြားပေးပါတယ်။ ဆရာ/ဆရာမ နဲ့ ကျောင်းသား/သူ အချိုးကျထားရှိပါတယ်။
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    ၇။ ကျောင်းက ဘယ်နှစ်လပိုင်းမှာစဖွင့်ပါသလဲ။
                  </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    မူကြိုဆိုရင် တစ်နှစ်မှာ Semester နှစ်ခုရှိပါတယ်။ June Semester နဲ့ December Semester ဆိုပြီး နှစ်စဉ်
                    June မှာတစ်ကြိမ် ၊ December မှာတစ်ကြိမ် ဖွင့်လှစ်ပါတယ်။
                    မူလတန်းကျောင်းနဲ့ အလယ်တန်းကျောင်းကတော့ ပညာရေးဝန်ကြီးဌာနကသတ်မှတ်ထားတဲ့အတိုင်း နှစ်စဉ် June လမှာ
                    ဖွင့်လှစ်ပါတယ်။
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  letter-spacing: 5px;
  font-weight: var(--cus-fw-semibold);
  color: var(--cus-secondary);
}

.sub-title {
  color: var(--cus-primary)
}

.list-title {
  text-transform: uppercase;
  font-weight: var(--cus-fw-semibold);
  font-size: var(--cus-fs-xx-large);
  color: var(--cus-white);
  background-color: var(--cus-primary);
}

.list-box {
  // border: 1px solid var(--cus-dark)
}

.accordion-item:first-of-type,
.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-button {
  border-radius: 0 !important;
}

.accordion-body {
  white-space: pre-wrap;
  line-height: 30px;
}

.lists {
  div {
    font-size: var(--fs-xx-large);
  }
}

.push-up-6 {
  margin-top: 6rem;
}</style>