<template>
  <div class="text-start mb-5">
    <h2 class="title text-uppercase mb-3">ကျောင်းသား/သူများ ရရှိနိုင်သည့် အခွင့် အရေး</h2>
    <div class="content_container">
      <p class="content">
        ရွှေ မော်ကွန်းကျောင်းတွင် မူကြို မှ အလယ်တန်းအထိ သင်ကြားမှု ပြုလုပ်ပေးပြီး ကျောင်းသား/ ကျောင်းသူများမှာလည်း
        ကျောင်းမှ သင်ကြားပေးသော သင်ရိုးနှင့် ထပ်ဆောင်းသင်ရိုး(နိုင်ငံတကာသင်ရိုး)ကို အပြည့်အဝ သင်ယူနိုင်သည့် အခွင့်
        အရေးရရှိခြင်း။
      </p>
      <p class="content">
        ရွှေ မော်ကွန်းကျောင်းမှ တာဝန်ယူမှု တာဝန်ခံမှု ရှိသော ခေါင်းဆောင်ကောင်း၊ ကိုယ်ချင်းစာစိတ် နှင့်
        အကောင်းမြင်ဝါဒ ရှိသောနိုင်ငံသားကောင်း ၊ ပူးပေါင်းပါဝင်ဆောင်ရွက်နိုင်သူ၊ အပြန်အလှန် ပြောဆို ဆက်သွယ်
        နိုင်စွမ်းရှိသူ၊ တီထွင် ဖန်တီးနိုင်သူ၊ စူးစမ်း လေ့လာ နိုင်စွမ်းရှိသူ၊ ဂီတ၊ အားကစား အစရှိသော မိမိတို့ ထူးချွန်ရာ
        နယ်ပယ်တွင် ကျွမ်းကျင်သော ပညာရှင် မွေးထုတ်ပေးနိုင်ရန် ဆောင်ရွက် နေပါသည်။
      </p>
    </div>
  </div>

  <div class="row gy-3 gy-lg-0">
    <AdmissionLinkCard v-for="card in benefitCards" :key="card.id" :card="card" class="col-12 col-sm-6 col-lg-3">
    </AdmissionLinkCard>
  </div>
</template>

<script>
import AdmissionLinkCard from '@/components/Admission/AdmissionLinkCard.vue'
export default {
  components: {
    AdmissionLinkCard,
  },
  data() {
    return {
      benefitCards: [
        { id: 0, title: 'required documentation', class: 'image-1', url: 'RequiredDocumentation' },
        { id: 0, title: 'fees', class: 'image-2', url: 'Fees' },
        { id: 0, title: 'faq', class: 'image-3', url: 'Fees' },
        { id: 0, title: 'waitlist form', class: 'image-4', url: 'waitinglist' },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: var(--cus-secondary-bold);
  font-weight: var(--cus-fw-semibold);
  text-align: left;
}

.content_container {
  p {
    text-align: justify;
  }
}

.content {
  line-height: 30px;
  text-indent: 30px;
}</style>