<template>
  <nav class="navbar navbar-expand-lg nav_in navbar-dark justify-content-end justify-content-lg-start">
    <div class="container-fluid text-start justify-content-end justify-content-lg-start">
      <button class="navbar-toggler" type="button" @click="navshow()" data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item mb-2 mt-2">
            <router-link :to="{ name: 'Home', params: { schtype: schtype } }" class="cus_nav" aria-current="page">
              <span v-if="font_type == 1">Home</span>
              <span v-if="font_type == 2">မူလစာမျက်နှာ</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link :to="{ name: 'AboutSchool' }" class="nav-link cus_nav">
              <span v-if="font_type == 1">About us</span>
              <span v-if="font_type == 2">ကျောင်း</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <div @click="$emit('openAcademicBar')" class="nav-link cus_nav hover-cursor">
              <span v-if="font_type == 1">Academic</span>
              <span v-if="font_type == 2">ပညာရေး</span>
            </div>
          </li>
          <li class="nav-item mb-2 mt-2">
            <div @click="$emit('openSubNavbar')" class="nav-link cus_nav hover-cursor">
              <span v-if="font_type == 1">Admission</span>
              <span v-if="font_type == 2">၀င်ခွင့်</span>
            </div>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link :to="{ name: 'Career' }" class="nav-link cus_nav">
              <span v-if="font_type == 1">Career</span>
              <span v-if="font_type == 2">စည်းကမ်းများ</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link :to="{ name: 'Contactus' }" class="nav-link cus_nav">
              <span v-if="font_type == 1">Contact us</span>
              <span v-if="font_type == 2">ဆက်သွယ်ရန်</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link :to="{ name: 'Other' }" class="nav-link cus_nav">
              <span v-if="font_type == 1">News and Activities</span>
              <span v-if="font_type == 2">လှုပ်ရှားမှုများ</span>
            </router-link>
          </li>
          <li class="nav-item mb-2 mt-2">
            <router-link class="nav-link cus_nav" :to="{ name: 'login', params: { userId: 3 } }">Admin
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['schtype', 'font_type']
}
</script>

<style lang="scss" scoped>
.nav-item {
  &:not(:last-child) {
    @include breakpoint(large) {
      margin-right: 10px;
    }
  }
}
</style>