<template>
  <h1 class="fw-bold mtext text-center">
    TEACHING AND LEARNING SPECIALISTS TEAM
  </h1>
  <div class="row justify-content-center align-items-start gx-md-3">
    <!--  -->
    <div v-for="dat in data2" class="col-md-6 col-xl-4  d-flex justify-content-center">
      <div>
        <div class="div_teacher_photo mb-3">
          <img :src=dat.teacherimage class="t_photo m-2" alt="">
        </div>
        <div class=" div_teacher_text m-2" style="text-align:justify;">
          <h2 class="t_name m-2" style="text-align:center;color:purple;font-size: 15px">
            {{ dat.position }}
          </h2>
          <h2 class="t_des m-2" style="text-align:center;color:goldenrod;font-size: 25px;">
            {{ dat.name }}
          </h2>
          <p class="t_des1 m-2" style="text-align:center;">
            {{ dat.studied }}
            <span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data2: {
      default: []
    }
  },
  computed: {
    font_type() {
      return this.$store.state.font_type
    }
  }
}
</script>

<style lang="scss">
.t_photo {
  border: 0px;
  border-style: solid;
  border-radius: 10px;
  object-fit: cover;
  width: 200px;

  @include breakpoint(medium) {
    height: 350px;
    width: 350px;
  }

}

.t_name {
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  text-align: center;
}

.t_des {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin-right: 5px;
}

.t_des1 {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin-right: 5px;

}
</style>