<template>
  <div class="text-start wrapper">
    <header>
      <div class="container py-5">
        <h1 class="heading mb-4">
          <slot name="title"></slot>
        </h1>
        <div id="No" class="container-scroll">
          <div class="d-flex flex-nowrap align-items-center justify-items-center">
            <slot name="header"></slot>
          </div>
        </div>
      </div>
    </header>

    <div class="container px-3 pb-5">
        <div id="testing" class="container-scroll">
          <div class="course-list">
            <slot name="body"></slot>
          </div>
      </div>
    </div>

    <div class="container-fluid videos-bg p-3">
      <div class="container-scroll videos-wrapper">
        <div class="videos">
          <slot name="videos"></slot>
        </div>
      </div>
    </div>
  </div>

  <footer>
    <div class="container-fluid">
      <div class="row justify-content-center p-5">
        <div class="col-sm-4">
          <div class="bd_footer">
            <h1 class="text-start text-uppercase" style="font-size:25px;font-weight:900">Shwe Maw Kun</h1>
            <p class="text-start mb-1 fw-normal text-white">About Us</p>
            <p class="text-start mb-1 fw-normal text-white">Distribution and Manufacturing</p>
            <p class="text-start mb-1 fw-normal text-white">Our Purpose</p>
            <p class="text-start mb-1 fw-normal text-white">Our Vision and Mission</p>
            <p class="text-start mb-1 fw-normal text-white">Our Culture</p>
            <p class="text-start mb-1 fw-normal text-white">CSR Activities</p>
          </div>
        </div>
        <div class="col-sm-4 bd_footer">
          <i class="bi bi-geo-alt icon"></i>
          <div>
            <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900">{{ fontPath == 1 ?
              "Contact Us" : "ဆက်သွယ်ရန်ဖုန်းနံပတ်များ" }}</h1>
            <p class="mb-1 fw-normal text-start">{{ fontPath == 1 ? "09 123 456 789" : "၀၉ ၁၂၃ ၄၅၆ ၇၈၉" }}</p>
            <p class="mb-1 fw-normal text-start">{{ fontPath == 1 ? "09 987 343 565" : "၀၉ ၉၈၇ ၃၄၃ ၅၆၅" }}</p>

          </div>
        </div>
        <!-- here -->
        <div class="col-sm-4 footer-links bd_footer">
          <div class="d-flex justify-content-center social-links footer_cent">
            <div>
              <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900;">{{ fontPath == 1 ? "Address" : "ဆက်သွယ်ရန်လိပ်စာ" }}</h1>
              <p class="mb-1 fw-normal text-start">
                {{ fontPath == 1 ? 'No. 20/10,Shwe Chi Street, (Corner of Yae Htwet Oo Street),Yae Aye Kwin Quarter, Taunggyi, Shan State, Myanmar' : ' အမှတ်(၂၀/၁၀)၊ရွှေချည်လမ်း နှင့် ရေထွက်ဦး လမ်းထောင့်၊ ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။' }}
                <br />
              </p>
            </div>
          </div>
        </div>
        <!-- here -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  created() {
    this.$store.state.footer = false
  },
  computed: {
    fontPath() {
      return this.$store.state.font_type;
    }
  },
  beforeUnmount() {
    this.$store.state.footer = true
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--cus-primary);
}

.heading {
  text-transform: uppercase;
  color: var(--cus-secondary);
  font-weight: var(--cus-fw-semibold);
}

.btn {
  background-color: var(--cus-white);
  min-width: 120px;
  text-transform: uppercase;
  color: var(--cus-primary)
}

.label-bar {
  background-color: var(--cus-grey);
  border-bottom: 1px solid #808080;

  .label {
    font-size: var(--cus-fs-large);
    color: var(--cus-secondary-bold);
    font-weight: var(--cus-fw-semibold);
  }
}

.course-list {
  width: 600px;
  background-color: var(--cus-white);

  @include breakpoint(medium) {
    width: auto;
  }
}

.video {
  background-color: white;
  width: 100%;
  height: 300px;
}

.videos {
  width: 900px;

  @include breakpoint(small) {
    width: 1140px;
  }

  @include breakpoint(large) {
    width: 100%;
  }
}

.videos-bg {
  background-color: var(--cus-grey);
}

.videos-wrapper {
  @include breakpoint(xlarge) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @include breakpoint(xxlarge) {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.container-scroll {
  overflow-x: scroll;
  @include breakpoint(large) {
    overflow: unset;
  }
}

.wrapper {
  background-color: var(--cus-primary);
}

footer {
  background-image: url('@/assets/img/background.png');
  background-position: center;
  background-size: cover;
}
</style>