<template>
  <AcademicLayout>
    <template #title>
      International Secondary
    </template>

    <template #header>
      <a href="#level7" class="btn me-3 rounded-pill">
        level-7
      </a>
      <a href="#level8" class="btn me-3 rounded-pill">
        level-8
      </a>
      <a href="#level9" class="btn me-3 rounded-pill">
        level-9
      </a>
      <a href="#cae" class="btn me-3 rounded-pill">
        cae
      </a>
    </template>

    <template #body>
      <div class="row label-bar">
        <div class="col-6">
          <div class="label py-3 text-uppercase text-center">
            intake
          </div>
        </div>
        <div class="col-6">
          <div class="label py-3 text-uppercase">area of learning</div>
        </div>
      </div>
      <Area v-for="area in payload" :area="area"></Area>
    </template>

    <template #videos>
      <div class="row g-3">
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/smk-cover-song-2.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/Shwe-Maw-Kun-Pre-School-3.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/smk-cover-song-2.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </template>
  </AcademicLayout>
</template>

<script>
import AcademicLayout from '@/views/academic/AcademicLayout.vue'
import Area from '@/components/Academic/Area.vue'
export default {
  components: {
    Area,
    AcademicLayout
  },
  data() {
    return {
      payload: [
        {
          id: 'level7',
          title: 'B1 PRELIMINARY',
          titleDetail: '(PET FOUNDATION, LEVEL-7)',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & APRIL</span>',
          items: [
            {
              lists: [
                'English',
                'Mathematics',
                'Computing',
                'Global Citizenship',
              ]
            },
            {
              lists: [
                'Digital Learning for Skill Enhancement',
              ]
            },
            {
              lists: [
                'Mathematics',
                'English',
                'Science',
                'Coding',
              ]
            },
          ]
        },
        {
          id: 'level8',
          title: 'B1 PRELIMINARY',
          titleDetail: '(PET, LEVEL-8)',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & APRIL</span>',
          items: [
            {
              lists: [
                'English',
                'Mathematics',
                'Computing',
                'Global Citizenship',
              ]
            },
            {
              lists: [
                'Digital Learning for Skill Enhancement',
              ]
            },
            {
              lists: [
                'Mathematics',
                'English',
                'Science',
                'Coding',
              ]
            },
          ]
        },
        {
          id: 'level9',
          title: 'B1 FIRST',
          titleDetail: '(FEC FOUNDATION, LEVEL-9)',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & APRIL</span>',
          items: [
            {
              lists: [
                'English',
              ]
            },
          ]
        },
        {
          id: 'cae',
          title: 'C1 ADVANCED',
          titleDetail: '(CAE)',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & APRIL</span>',
          items: [
            {
              lists: [
                'English',
              ]
            },
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--cus-primary);
}

.heading {
  text-transform: uppercase;
  color: var(--cus-secondary);
  font-weight: var(--cus-fw-semibold);
}

.btn {
  background-color: var(--cus-white);
  min-width: 120px;
  text-transform: uppercase;
  color: var(--cus-primary)
}

.label-bar {
  background-color: var(--cus-grey);
  border-bottom: 1px solid #808080;

  .label {
    font-size: var(--cus-fs-large);
    color: var(--cus-secondary-bold);
    font-weight: var(--cus-fw-semibold);
  }
}

.course-list {
  width: 600px;
  background-color: var(--cus-white);

  @include breakpoint(medium) {
    width: auto;
  }
}

.video {
  background-color: white;
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.videos {
  width: 900px;

  @include breakpoint(small) {
    width: 1140px;
  }

  @include breakpoint(large) {
    width: 100%;
  }
}

.videos-bg {
  background-color: var(--cus-grey);
}

.videos-wrapper {
  @include breakpoint(xlarge) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @include breakpoint(xxlarge) {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.container-scroll {
  overflow-x: scroll;

  @include breakpoint(large) {
    overflow: unset;
  }
}
</style>