<template>
  <AcademicLayout>
    <template #title>
      Pre school (early years)
    </template>

    <template #header>
      <a href="#Nusery1" class="btn me-3 rounded-pill">
        nusery 1
      </a><a href="#Nusery2" class="btn me-3 rounded-pill">
        nusery 2
      </a>
      <a href="#prekg1" class="btn me-3 rounded-pill">
        pre-kg 1
      </a>
      <a href="#prekg2" class="btn me-3 rounded-pill">
        pre-kg 2
      </a>
      <a href="#prekg3" class="btn me-3 rounded-pill">
        pre-kg 3
      </a>
    </template>

    <template #body>
      <div class="row label-bar">
        <div class="col-6">
          <div class="label py-3 text-uppercase text-center">
            intake
          </div>
        </div>
        <div class="col-6">
          <div class="label py-3 text-uppercase">area of learning</div>
        </div>
      </div>
      <Area v-for="area in payload" :area="area"></Area>
    </template>

    <template #videos>
      <div class="row g-3">
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/smk-cover-song-2.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/Shwe-Maw-Kun-Pre-School-3.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-4">
          <video controls class="video">
            <source src="@/assets/videos/smk-cover-song-2.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </template>
  </AcademicLayout>
</template>

<script>
import Area from '@/components/Academic/Area.vue'
import AcademicLayout from '@/views/academic/AcademicLayout.vue'
export default {
  components: {
    Area,
    AcademicLayout
  },
  data() {
    return {
      payload: [
        {
          id: 'Nusery1',
          title: 'NUSERY 1',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & DECEMBER</span>',
          items: [
            {
              title: 'Communication and Language',
              lists: [
                'English',
                'Myanmar'
              ]
            },
            {
              title: 'Litercy',
              lists: [
                'English & Myanmar',
                'English Phonics',
              ]
            },
            {
              title: 'Personal, Social, Health & Emotional Development',
              lists: [
                'Life Skills',
                'Personal Hygiene',
                'Myanmar Short Stories for Moral Value',
              ]
            },
            {
              title: 'Physical Development',
              lists: [
                'Fine & Gross Motor Skills',
                'Music & Movement',
                'Yoga',
              ]
            },
            {
              title: 'Numeracy/ Mathematics',
              lists: null
            },
            {
              title: 'STEAM(Science, Technology, Engineering, Art & Maths)',
              lists: null
            },
            {
              title: 'Mindfulness',
              lists: null
            },
            {
              title: 'Montessori Education',
              lists: null
            },
            {
              title: 'Digital Learning for Skill Enhacement',
              lists: [
                'Maths',
                'English'
              ]
            },
          ]
        },
        {
          id: 'Nusery2',
          title: 'NUSERY 2',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & DECEMBER</span>',
          items: [
            {
              title: 'Communication and Language',
              lists: [
                'English',
                'Myanmar'
              ]
            },
            {
              title: 'Litercy',
              lists: [
                'English & Myanmar',
                'English Phonics',
              ]
            },
            {
              title: 'Personal, Social, Health & Emotional Development',
              lists: [
                'Life Skills',
                'Personal Hygiene',
                'Myanmar Short Stories for Moral Value',
              ]
            },
            {
              title: 'Physical Development',
              lists: [
                'Fine & Gross Motor Skills',
                'Music & Movement',
                'Yoga',
              ]
            },
            {
              title: 'Numeracy/ Mathematics',
              lists: null
            },
            {
              title: 'STEAM(Science, Technology, Engineering, Art & Maths)',
              lists: null
            },
            {
              title: 'Mindfulness',
              lists: null
            },
            {
              title: 'Montessori Education',
              lists: null
            },
            {
              title: 'Digital Learning for Skill Enhacement',
              lists: [
                'Maths',
                'English'
              ]
            },
          ]
        },
        {
          id: 'prekg1',
          title: 'PRE-KG 1',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & DECEMBER</span>',
          items: [
            {
              title: 'Communication and Language',
              lists: [
                'English',
                'Myanmar'
              ]
            },
            {
              title: 'Litercy',
              lists: [
                'English & Myanmar',
                'English Phonics',
              ]
            },
            {
              title: 'Personal, Social, Health & Emotional Development',
              lists: [
                'Life Skills',
                'Personal Hygiene',
                'Myanmar Short Stories for Moral Value',
              ]
            },
            {
              title: 'Physical Development',
              lists: [
                'Fine & Gross Motor Skills',
                'Music & Movement',
                'Yoga',
              ]
            },
            {
              title: 'Numeracy/ Mathematics',
              lists: null
            },
            {
              title: 'STEAM(Science, Technology, Engineering, Art & Maths)',
              lists: null
            },
            {
              title: 'Mindfulness',
              lists: null
            },
            {
              title: 'Montessori Education',
              lists: null
            },
            {
              title: 'Digital Learning for Skill Enhacement',
              lists: [
                'Maths',
                'English'
              ]
            },
          ]
        },
        {
          id: 'prekg2',
          title: 'PRE-KG 2',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & DECEMBER</span>',
          items: [
            {
              title: 'Communication and Language',
              lists: [
                'English',
                'Myanmar'
              ]
            },
            {
              title: 'Litercy',
              lists: [
                'English & Myanmar',
                'English Phonics',
              ]
            },
            {
              title: 'Personal, Social, Health & Emotional Development',
              lists: [
                'Life Skills',
                'Personal Hygiene',
                'Myanmar Short Stories for Moral Value',
              ]
            },
            {
              title: 'Physical Development',
              lists: [
                'Fine & Gross Motor Skills',
                'Music & Movement',
                'Yoga',
              ]
            },
            {
              title: 'Numeracy/ Mathematics',
              lists: null
            },
            {
              title: 'STEAM(Science, Technology, Engineering, Art & Maths)',
              lists: null
            },
            {
              title: 'Mindfulness',
              lists: null
            },
            {
              title: 'Montessori Education',
              lists: null
            },
            {
              title: 'Digital Learning for Skill Enhacement',
              lists: [
                'Maths',
                'English'
              ]
            },
          ]
        },
        {
          id: 'prekg3',
          title: 'PRE-KG 3',
          subtitle: '<span class="text-cus-secondary">INTAKE</span> - <span class="text-cus-primary">JUNE & DECEMBER</span>',
          items: [
            {
              title: 'Communication and Language',
              lists: [
                'English',
                'Myanmar'
              ]
            },
            {
              title: 'Litercy',
              lists: [
                'English & Myanmar',
                'English Phonics',
              ]
            },
            {
              title: 'Personal, Social, Health & Emotional Development',
              lists: [
                'Life Skills',
                'Personal Hygiene',
                'Myanmar Short Stories for Moral Value',
              ]
            },
            {
              title: 'Physical Development',
              lists: [
                'Fine & Gross Motor Skills',
                'Music & Movement',
                'Yoga',
              ]
            },
            {
              title: 'Numeracy/ Mathematics',
              lists: null
            },
            {
              title: 'STEAM(Science, Technology, Engineering, Art & Maths)',
              lists: null
            },
            {
              title: 'Mindfulness',
              lists: null
            },
            {
              title: 'Montessori Education',
              lists: null
            },
            {
              title: 'Digital Learning for Skill Enhacement',
              lists: [
                'Maths',
                'English'
              ]
            },
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--cus-primary);
}

.heading {
  text-transform: uppercase;
  color: var(--cus-secondary);
  font-weight: var(--cus-fw-semibold);
}

.btn {
  background-color: var(--cus-white);
  min-width: 120px;
  text-transform: uppercase;
  color: var(--cus-primary)
}

.label-bar {
  background-color: var(--cus-grey);
  border-bottom: 1px solid #808080;

  .label {
    font-size: var(--cus-fs-large);
    color: var(--cus-secondary-bold);
    font-weight: var(--cus-fw-semibold);
  }
}

.course-list {
  width: 600px;
  background-color: var(--cus-white);

  @include breakpoint(medium) {
    width: auto;
  }
}

.video {
  background-color: white;
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.videos {
  width: 900px;

  @include breakpoint(small) {
    width: 1140px;
  }

  @include breakpoint(large) {
    width: 100%;
  }
}

.videos-bg {
  background-color: var(--cus-grey);
}

.videos-wrapper {
  @include breakpoint(xlarge) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @include breakpoint(xxlarge) {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.container-scroll {
  overflow-x: scroll;

  @include breakpoint(large) {
    overflow: unset;
  }
}
</style>