<template>
    <div>
    
        <div class="p-5" style="background-color:purple">
            <h1 class="text-start pb-3" style="color:gold">PRE-SCHOOL</h1>
            <table class="table table-bordered table-hover bg-white" >
            <thead class="bg-light" style="color:goldenrod;text-shadow: black 1px 1px 1px 1px;">
                <tr class="">
                    <th>No</th>
                    <th></th>
                    <th>INTAKE</th>
                    <th>AREAS OF LEARNING</th>
                    <th>SCHOOL FEES</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="color:goldenrod">
                    <b>
                        B1
PRELIMINARY
(PET FOUN-DATION)
LEVEL_7
                    </b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        </div>

        <div class="row gx-0 p-3 d-flex justify-content-center">
            <div class="col-4">
                <img src="../assets/img/1_1660702150.png" style="width:100%;height:100%" alt="">
            </div>
            <div class="col-6">
                <ul class="list-group">
                <li class="list-group-item " style="background-color:lightgray;color:purple">ENGLISH</li>
                <li class="list-group-item" style="background-color:purple;color:white">MATHS</li>
                <li class="list-group-item" style="background-color:lightgray;color:purple" >SCIENCE</li>
                <li class="list-group-item" style="background-color:purple;color:white">COMPUTING</li>
                <li class="list-group-item"  style="background-color:lightgray;color:purple">GLOBAL</li>
                <li class="list-group-item" style="background-color:purple;color:white">MUSIC</li>
                </ul>
            </div>
        </div>
   
    </div>
</template>

<script>
export default {
    data () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>