<template>
  <footer>
    <div class="Part7">
      <div class="footer">
        <div class=" footer_div">
          <div class="container py-3">
            <div class="row gx-0 gy-3 justify-content-center">
              <div class="col-sm-4 bd_footer">
                <div>
                  <h1 class="text-start text-uppercase" style="font-size:25px;font-weight:900">Shwe Maw Kun</h1>
                  <p class="text-start mb-1 fw-normal text-white">About Us</p>
                  <p class="text-start mb-1 fw-normal text-white">Distribution and Manufacturing</p>
                  <p class="text-start mb-1 fw-normal text-white">Our Purpose</p>
                  <p class="text-start mb-1 fw-normal text-white">Our Vision and Mission</p>
                  <p class="text-start mb-1 fw-normal text-white">Our Culture</p>
                  <p class="text-start mb-1 fw-normal text-white">CSR Activities</p>
                </div>
              </div>
              <div class="col-sm-4 bd_footer">
                <i class="bi bi-geo-alt icon"></i>
                <div>
                  <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900">{{ font_type == 1 ?
                    "Contact Us" : "ဆက်သွယ်ရန်ဖုန်းနံပါတ်များ" }}</h1>
                  <p class="mb-1 fw-normal text-start">{{ font_type == 1 ? "09 252447066" : "၀၉ ၂၅၂၄၄၇၀၆၆" }}</p>
                  <p class="mb-1 fw-normal text-start">{{ font_type == 1 ? "09 428377766" : "၀၉ ၄၂၈၃၇၇၇၆၆" }}</p>

                </div>
              </div>
              <!-- here -->
              <div class="col-sm-4 footer-links bd_footer">
                <div class="d-flex justify-content-center social-links footer_cent">
                  <div>
                    <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900;">{{ font_type == 1 ? "Address" : "ဆက်သွယ်ရန်လိပ်စာ" }}</h1>
                    <p class="mb-1 fw-normal text-start">
                      {{ font_type == 1 ? 'No. 20/10,Shwe Chi Street, (Corner of Yae Htwet Oo Street),Yae Aye Kwin Quarter, Taunggyi, Shan State, Myanmar' : ' အမှတ်(၂၀/၁၀)၊ ရွှေခြည်လမ်း နှင့် ရေထွက်ဦး လမ်းထောင့်၊ ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။' }}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <!-- here -->
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row" v-if="path1 == true">
          <div class="col-md-4 ">
            <div class="bottom-icon">
              <img src="@/assets/img/footer/1.png" class="footer-logo-img" alt="">
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="bottom-icon">
              <img src="@/assets/img/footer/2.png" class="footer-logo-img" alt="">
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="bottom-icon">
              <img src="@/assets/img/footer/3.png" class="footer-logo-img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    font_type: [String, Number],
    path1: Boolean
  }
}
</script>

<style lang="scss" scoped>
.footer-logo-img {
  width: 200px;
  height: 100px;
  object-fit: contain;

  @include breakpoint(medium) {
    height: 200px;
  }
}
</style>