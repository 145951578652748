<template>
  <section>
    <div class="py-5">
      <div class="accordion" id="accordionExample">
        <div v-for="(teacher, index) in this.teachers" :key="{ index }" class="accordion-item">
          <h2 class="accordion-header" :id="teacher.heading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#' + teacher.collapse" aria-expanded="false" :aria-controls="teacher.collapse">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="plus-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="minus-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
              </svg>
              {{ teacher.title }}
            </button>
          </h2>
          <div :id="teacher.collapse" class="accordion-collapse collapse" :aria-labelledby="teacher.heading"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="avice_color" style="color:white">
                <div class="row gx-3 gy-3 p-4">
                  <div class="col-12">
                    <div class="teacher-content">
                      <h2 style="color:#F3cd5d;text-align:left;">{{ teacher.title }}</h2>
                      <div class="teacher-info">
                        <div class="clear-fix">
                          <div class="teacher-image">
                            <img :src="require(`@/assets/img/aboutus-teachers/${teacher.img}`)" alt="">
                          </div>
                          <p style="text-align:start;line-height: 30px;" v-html="teacher.bio">
                          </p>
                        </div>
                      </div>
                      <p style="text-align: start;line-height: 30px;" v-html="teacher.pre">
                      </p>
                      <div v-if="teacher.logos?.length > 0" class="mt-5 text-center">
                        <h5 class="teacher-logo-title">British council credential</h5>
                        <img v-for="logo in teacher.logos" :key="logo" class="teacher-logo" :src="require(`@/assets/img/aboutus-teachers/logos/${logo}`)" alt="">
                        <!-- <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/pro-dev.png" alt="">
                        <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/lead-dev.png" alt="">
                        <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/expert-edu-starter-30.png"
                          alt=""> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      teachers: [
        {
          id: 'accordion1',
          title: "PRINCIPAL'S MESSAGE",
          img: 'daw-win-sandar-tint-shein.jpg',
          bio: `ကျွန်မကတော့ တောင်ကြီးမြို့ ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ တာဝန် ထမ်းဆောင်နေတဲ့
                တွဲဖက်ကျောင်းအုပ် ဒေါ်ဝင်းစန္ဒာတင့်ရှိန်ဖြစ်ပါတယ်။
                ကျွန်မ ပညာရေးတက္ကသိုလ်က ကျောင်းဆင်းပီး အ.ထ.က(၃)တောင်ကြီးမှာ အထက်တန်းပြအဖြစ် ပထမဆုံး
                တာဝန်ထမ်းဆောင်ခဲ့တာဖြစ်ပြီး အဌမတန်း၊ နဝမတန်း၊ ဒသမတန်းတို့မှာ မြန်မာစာ၊ ပထဝီဝင်၊
                သမိုင်းဘာသာရပ်တွေ သင်ကြားခဲ့ပါတယ်။
                <br>
                နောက်တောင်ကြီးပညာရေးကောလိပ်မှာ လက်ထောက်ကထိက၊ လက်ထောက်မော်ကွန်းထိန်း၊ ဌာနမှူး (စီမံ)
                တာဝန်များထမ်းဆောင်ခဲ့ပါတယ်။ ပညာရေးဌာနမှာ လုပ်သက် (၃၂)နှစ် လုပ်ခဲ့ပါတယ်။
                အငြိမ်းစားယူပြီးနောက် ကိုယ်ပိုင်ကျောင်းတစ်ခုမှာ ကျောင်းအုပ်တာဝန်ထမ်းဆောင်ခဲ့ပါတယ်။
                ယခုလက်ရှိမှာတော့
                ရွှေမော်ကွန်းကျောင်းမှာ တွဲဖက်ကျောင်းအုပ်အနေဖြင့်
                ကျွန်မရဲ့လုပ်ငန်းအတွေ့အကြုံပေါ်မူတည်ပြီး ရုံးပိုင်းဆိုင်ရာလုပ်ငန်း၊ ဆရာ ဆရာမ
                သင်ကြားရေးအပိုင်း၊
                ကျောင်းသား
                ကျောင်းသူလေးများအတွက် တတ်မြောက်မှုစစ်ဆေးရေးအပိုင်းတွေကို
                ညွှန်ကြားကြီးကြပ်ပေးပါတယ်ရှင်။
                <br>
                သင်ကြားသင်ယူမှု လုပ်ငန်းစဉ်များအပြင်် ကျောင်းသား/သူလေးတွေရဲ့ကျန်းမာရေး၊စည်းကမ်းလိုက်နာစေရေး၊
                ယဉ်ကျေးသိမ်မွေ့စွာပြောဆိုနေထိုင်တတ်စေရေး နှင့်
                လူမှုဆက်ဆံရေးတို့ပိုမိုကောင်းမွန်စေအောင်တို့ကိုလည်း
                အလေးထားဆောင်ရွက်ပေးပါတယ်။
                <br>
                ကျွန်မတို့ ရွှေမော်ကွန်းကျောင်းမှာ ဆရာ ဆရာမကလည်း ကလေးများရဲ့ သင်ကြားသင်ယူမှု ၊
                တတ်မြောက်မှုတို့ကို
                အစဉ်တစိုက်အလေးထားဆောင်ရွက်ပေးလျက်ရှိကြပါတယ်။
                ပြောင်းလဲ တိုးတက်နေသော ပညာခေတ်စနစ်တွင် ကလေးများအား ထိရောက်စွာ တီထွင်ဆန်းသစ်သော
                အဆင့်မီသင်ကြားနည်းများဖြင့်
                သင်ကြားပြသပေးနိုင်ရန် ဆရာ ဆရာမများအတွက် ဘာသာရပ်ဆိုင်ရာ မွမ်းမံသင်တန်းများနှင့်
                စွမ်းရည်မြှင့်သင်တန်းများကိုလဲ အစဉ်တစိုက်ပံ့ပိုးပေးလျက်ရှိပါတယ်။
                <br>
                ကျွန်မတို့ကျောင်းမှာ National နဲ့ International သင်ရိုးကိုသင်ကြားပေးပြီး English language ကို
                အသုံးပြုပြောဆိုနေကြပေမဲ့လဲ မြန်မာ့ယဉ်ကျေးမှုနဲ့အညီ ဝတ်စားဆင်ယင်တတ်ဖို့၊
                ယဉ်ကျေးစွာပြောဆိုတတ်ဖို့၊ ထူးချွန်ထက်မြက်ပြီး စာနာနားလည်တတ်သော
                နိုင်ငံသားကောင်းများဖြစ်လာစေဖို့
                ကိုလဲ
                အဓိကထား ကြီးကြပ်ဆောင်ရွက်ပေးပါတယ်။`,
          pre: `ဒေါ်ဝင်းစန္ဒာတင်ရှိန်<br>
                တွဲဖက်ကျောင်းအုပ်ဆရာမကြီး<br>
                B.Ed (၁၉၇၅-၁၉၈၀)`,
          heading: 'heading1',
          collapse: 'collapse1',
        },
        {
          id: 'accordion2',
          title: "HEAD TEACHER MESSAGE",
          img: 'daw-nang-thet-nwe-oo.jpg',
          bio: `ကျွန်မနာမည်ကတော့ ရွှေမော်ကွန်းကျောင်းမှာ Head teacher အနေနှင့် International Curriculum ရဲ့
                Teaching
                and
                Learning အပိုင်းကို တာဝန်ယူလုပ်ကိုင်ပေးနေတဲ့ ဒေါ်နန်းသက်နွယ်ဦးဖြစ်ပါတယ်ရှင်။
                <br>
                ကျွန်မကတော့ အမေရိကန်သံရုံးကပေးအပ်တဲ့ English Access Micro Scholarship Program Award ကို
                ရရှိခဲ့ပါတယ်။
                သင်ကြားရေးလုပ်သက်အနေဖြင့် International ကျောင်းတစ်ကျောင်းတွင် (၅) နှစ်လုပ်ခဲ့ပြီး ယခု
                ရွှေမော်ကွန်းကျောင်းတွင် ၂၀၁၆ မှ စတင်တာဝန်ထမ်းဆောင်ခဲ့ပါသည်။
                <br>
                ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင် ဆရာ ဆရာမများ၏ သင်ကြာမှုထိရောက်စေရန်
                အတန်းတွင်းသင်ကြားမှုများကိုအကဲဖြတ်စစ်ဆေးပေးပြီး အရည်အသွေးမြှင့်သင်တန်းများကိုလည်း
                စီစဉ်ပေးလျက်ရှိပါတယ်။
                အတန်းလိုက် ဘာသာရပ်ခွဲဝေမှုတာဝန်ကိုလဲ ထမ်းဆောင်ပေးပါတယ်။`,
          pre: `Daw Nang Thet New Oo <br>
                Head Teacher <br>
                B.A (English) <br>
                Certificate in TESOL Methodology (Canvas) <br>
                Certificate in Jolly Phonics <br>
                Cambridge International Diploma in Teaching and Learning (Candidate)`,
          heading: 'heading2',
          collapse: 'collapse2',
          logos: [
            'eng-for-teaching-3.png',
            'pro-dev.png',
            'lead-dev.png',
            'expert-edu-starter-30.png'
          ]
        },
         {
          id: 'accordion3',
          title: "DEPUTY HEADTEACHER",
          img: 'daw-nang-mya-nandar-soe.jpg',
          bio: `ကျွန်မကတော့ ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင် စည်းကမ်းပိုင်းဆိုင်ရာ နှင့်
                ဝန်ထမ်းရွေးချယ်
                ခန့်အပ်ရေးအပိုင်းတွင်တာဝန်ယူဆောင်ရွက်ပေးလျက်ရှိပါတယ်ရှင်။
                <br>
                ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းသည် ကျောင်းသား/သူလေးများ၏ သင်ကြားသင်ယူမှုတွင်
                ထူးချွန်ထက်မြက်စေရန်သာမက
                ယဉ်ကျေးလိမ္မာပြီး
                စည်းကမ်းကိုစနစ်တကျလိုက်နာတတ်စေရန် အမြဲသွန်းသင်ဆုံးမပေးလျက်ရှိပါတယ်။ <br>
                ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင်် ကျောင်းကောင်စီအဖွဲ့ကို ဖွဲ့စည်းထားရှိပြီး
                ကျောင်းကောင်စီအဖွဲ့ဝင်ကျောင်းသား/သူများနှင့် ဆရာ ဆရာမများအတူတကွပူးပေါင်းပြီး
                ကျောင်းသား/သူများလိုက်နာရမည့် စည်းကမ်းများကိုချမှတ်ထားပါတယ်။`,
          pre: `Daw Nang Mya Nandar Soe <br>
                Deputy Head Teacher <br>
                B.A (Myanmar)`,
          heading: 'heading3',
          collapse: 'collapse3',
          logos: [
            'safeguard.png',
          ]
        },
        {
          id: 'accordion4',
          title: "Secondary School Coordinator/ English Subject Lead",
          img: 'daw-nang-moe-pwint-phyu.jpg',
          bio: `ကျွန်မကတော့ ဒေါ်နန်းမိုးပွင့်ဖြူ ဖြစ်ပါတယ်ရှင်။ လက်ရှိ ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ
                        Secondary School Coordinator နှင့် English Subject Lead အနေနဲ့ ပူးတွဲတာဝန်ထမ်းဆောင်နေပါတယ်။
                        ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းမှ
                        အလယ်တန်းကျောင်းသား/သူများကို နိုင်ငံတကာအဆင့်မီ
                        အရည်အသွေးပြည့်ဝသော ပညာရေးကို လက်လှမ်းမီအောင် လေ့ကျင့်ပျိုးထောင်ပေးနေပါတယ်။ <br>`,
          pre: `ဒေါ်နန်းမိုးပွင့်ဖြူ <br>
                        B.A (English)<br>
                        Certificate in TESOL Methodology (Canvas)<br>
                        Cambridge English Diploma in Teaching and Learning (Candidate)<br>`,
          heading: 'heading4',
          collapse: 'collapse4',
          logos: [
            'pro-dev.png',
            'safeguard.png'
          ]
        },
        {
          id: 'accordion5',
          title: "Primary School Coordinator",
          img: 'daw-tin-nilar-swe.jpg',
          bio: `ကျွန်မကတော့ ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းရဲ့ Primary School Coordinator
                              ဒေါ်တင်နီလာဆွေဖြစ်ပါတယ်ရှင်။
                              Kindergarten ကနေ Year 6 အထိ ကျောင်းသား/သူများ၏ သင်ကြားသင်ယူမှုမှာ တိုးတက်မှုရှိစေဖို့
                              ကြီးကြပ်ပေးပါတယ်။<br>`,
          pre: `ဒေါ်တင်နီလာဆွေ <br>
                              B.Sc (Physics) <br>
                              Cambridge English Diploma in Teaching and Learning (Candidate)`,
          heading: 'heading5',
          collapse: 'collapse5',
          logos: [
            'safeguard.png',
            'lead-dev.png',
          ]
        },
        {
          id: 'accordion6',
          title: "Weekend International Programme Coordinator",
          img: 'daw-nway-nway-ei.jpg',
          bio: `ကျွန်မကတော့ Weekend International Programme Coordinator ဒေါ်နွေးနွေးအိဖြစ်ပါတယ်။
                              သင်ကြားရေးလုပ်သက်အနေဖြင့် International ကျောင်းတစ်ကျောင်းတွင် (၄)နှစ်လုပ်ခဲ့ပြီး
                              ယခုရွှေမော်ကွန်းကျောင်းတွင်
                              ၂၀၁၇ မှ စတင်တာဝန်ထမ်းဆောင်ခဲ့ပါတယ်။<br>
                              ယခုလက်ရှိမှာတော့ ရွှေမော်ကွန်းကိုယ်ပိုင် အလယ်တန်းကျောင်းရဲ့ Weekend International Programme
                              Coordinator
                              အဖြစ်တာဝန်ထမ်းဆောင်နေပါတယ်ရှင့်။
                              ရွှေမော်ကွန်း ကျောင်းရဲ့ Weekend International Programme ကို ၂ဝ၂၂-၂၀၂၃ ပညာသင်နှစ်မှာ
                              ကလေးများအတွက်
                              အနာဂါတ်မှာ အထောက်အပံ့အများကြီးပေးနိုင်မဲ့
                              ဘာသာရပ်ကြီး(၅)ခုဖြစ်တဲ့ English, Maths, Science, Computing/Coding နှင့် Global citizenship
                              ဆိုပြီး
                              ထည့်သွင်းသင်ကြားပေးလျက်ရှိပါတယ်ရှင်။<br>
                              ကျွန်မတို့ ရွှေမော်ကွန်းကျောင်းမှာ Weekday တက်တဲ့ ကလေးနဲ့ Weekend တက်တဲ့ကလေးတွေအတွက်
                              သင်ရိုးတွေကို
                              ခွဲခြားထားတာမျိုးမရှိပါဘူး။
                              ရွှေမော်ကွန်းကျောင်းမှာတက်တဲ့ ဘယ်ကလေးကိုမဆို ပညာရေးအခွင့်အလမ်းတွေကို တန်းတူဖန်တီးပေးမှာပါပဲ။
                              ကျွန်မတို့
                              ရွှေမော်ကွန်းမိသားစုအနေနဲ့လဲ ပိုမိုကောင်းမွန်တဲ့ အဆင့်မီပညာရေးမျိုးကို
                              ကလေးများလက်လှမ်းမီအောင် ဖြည့်ဆည်းပေးနိုင်ဖို့ဆက်လက်ကြိုးစားသွားပါဦးမယ်ရှင်။`,
          pre: `Weekend International Programme Coordinator <br>
                              Daw Nway Nway Ei <br>
                              B.A (English) <br>
                              Certificate in TESOL Methodology (Canvas) <br>
                              Certificate in Jolly Phonics <br>
                              Cambridge English Diploma in Teaching and Learning Candidate)`,
          heading: 'heading6',
          collapse: 'collapse6',
          logos: [
            'clilforsec.png',
            'eng-for-teaching-2.png',
            'lead-dev.png',
            'expert-edu-starter-30.png'
          ]
        },
        {
          id: 'accordion7',
          title: "National Curriculum Coordinator",
          img: 'daw-kay-thi-khaing.jpg',
          bio: `ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ ကျွန်မရဲ့ အဓိကတာဝန်ကတော့ ပညာရေးဝန်ကြီး
                              ဌာနမှပြဌာန်းထားတဲ့ အခြေခံပညာ သင်ရိုးသင်ကြားရေးကို ကြီးကြပ်အုပ်ချုပ်ရပါတယ်ရှင့်။
                              ကလေးများသင်ခန်းစာသင်ယူမှုအပေါ် နားလည်တတ်မြောက်မှု ရှိ/မရှိ ကိုလည်း စာသင်ခန်းတွင်း
                              သင်ခန်းစာတစ်ခုသင်ပြီးတိုင်း ညွှန်ကြားထားတဲ့အတိုင်း အကဲဖြတ်စစ်ဆေးမှုတွေကိုလည်း ပြုလုပ်
                              ပေးပါတယ်။ ပညာသင်နှစ် တစ်နှစ်လုံးအတွက် ပညာရေးဝန်ကြီးဌာနက သတ်မှတ်ထားတဲ့ တတ်မြောက်မှုစစ်ဆေး ခြင်း
                              (၄) ကြိမ်ကိုလည်း သတ်မှတ်ချိန်အတိုင်း စစ်ဆေးပေးပါတယ်။`,
          pre: ` ဒေါ်ကေသီခိုင် <br>
                              National Curriculum Coordinator <br>
                              M.Sc (Biotech) <br>`,
          heading: 'heading7',
          collapse: 'collapse7',
          logos: [
            'clilforpri.png'
          ]
        },
        {
          id: 'accordion8',
          title: "Student Affairs/Head of School Counsellor",
          img: 'daw-nang-win-myint-aye.jpg',
          bio: `ကျွန်မက တော့ ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းမှာ Head of School Counsellor အနေနဲ့
                              ကျောင်းသားရေးရာ
                              အပိုင်း ကို အဓိက တာဝန်ယူထားပါတယ်။<br>
                              ကလေးသူငယ် စောင့်ရှောက်ရေးနှင့် ကာကွယ်ရေး (Safe Guarding and Child Protection) မူဝါဒများကို
                              နိုင်ငံတကာစံနှုန်းများနှင့်အညီ ပြုစုရေးဆွဲပြီး လိုက်နာကျင့်သုံးနေပါတယ်။
                              နည်းပညာဖွံ့ဖြိုးတိုးတက်လာတဲ့ ယခုခေတ်မှာဆိုရင် ဆရာ ဆရာမ များအနေဖြင့် ကလေးစိတ်ပညာကို
                              နားလည်ထားဖို့က
                              အလွန်ပင်အရေးကြီးပါတယ်။
                              ရွှေမော်ကွန်းကျောင်းမှာဆိုရင် ကလေးတွေရဲ့ပညာရေးကိုသာမက
                              စိတ်ခံစားချက်နဲ့အပြုအမူပြောင်းလဲမှုကိုလည်း
                              လမ်းမှန်ရောက်အောင် အပြုသဘောဆောင် ဆွေးနွေးပေးပါတယ်။
                              ကျောင်းသားကျောင်းသူများကို အကောင်းဆုံးပံ့ပိုးပေးနိုင်ရန် မိမိနှစ်သက်ရာ ဆရာ၊ဆရာမ (Trusted
                              Adult)
                              ကိုရွေးချယ်ပြီး လွတ်လပ်စွာ ရင်ဖွင့်ဆွေးနွေးတိုင်ပင်နိုင်အောင်လဲစီစဉ်ထားပါတယ်။`,
          pre: ` Daw Nang Win Myint Aye <br>
                              Student Affairs/Head of School Counsellor <br>
                              M.Sc. (Biotech) <br>
                              Certificate in Intermediate Counselling Training`,
          heading: 'heading8',
          collapse: 'collapse8',
          logos: [
            'safeguard.png',
          ]
        },
        {
          id: 'accordion9',
          title: "Early Years Trainers/ Advisor",
          img: 'daw-khin-khin-htay.jpg',
          bio: `ကျွန်မကတော့ ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင် မူလတန်းကြိုအတန်းများ၏သင်ကြားရေးကို
                              ကြီးကြပ်ပေးပါတယ်။
                              ထို့အပြင် ဆရာမများကိုလည်း ကလေးငယ်များအတွက်မရှိမဖြစ်လိုအပ်တဲ့ (၂၁)ရာစုစွမ်းရည်များကို
                              သင်ကြားပေးနိုင်ရန်
                              နှင့် မူလတန်းကြိုကလေးများအား သင်ကြားနည်းစနစ်အမျိုးမျိုးကို
                              လက်တွေ့အသုံးချသင်ကြားနိုင်စေရန်မွမ်းမံလေ့ကျင့်ပေးနေပါတယ်ရှင်။`,
          pre: `ဒေါ်ခင်ခင်ဌေး <br>
                              Pre-school Early Years Trainer <br>
                              B.A(History) <br>
                              Certificate In Early Childhood Care and Development (ECCD) <br>
                              Certificate in Early Years Teaching Programme, Bell English (UK) <br>`,
          heading: 'heading9',
          collapse: 'collapse9',
          logos: [
            'safeguard.png',
            'pro-dev.png',
          ]
        },
        {
          id: 'accordion10',
          title: "Pre School-Coordinator",
          img: 'daw-aye-thiri-khaing.jpg',
          bio: `ကျွန်မကတော့ ရွှေမော်ကွန်းကိုယ်ပိုင်မူလတန်းကြိုကျောင်းတွင်
                              ရုံးပိုင်းဆိုင်ရာလုပ်ငန်းတာဝန်များကို
                              စနစ်တကျဖြစ်အောင် စီစဉ်ပေးခြင်း၊ ကျောင်းသား/သူအသစ်များအတွက်
                              အသက်အရွယ်အလိုက် ဖွံ့ဖြိုးတိုးတက်မှုနှင့်ကိုက်ညီသော အတန်းများကို စီစစ်၍ခွဲခြားပေးခြင်း၊
                              သင်ကြားရေးပိုင်းနှင့်ပတ်သက်သည့်စာအုပ်များ၊ စာရွက်စာတမ်းများကို ပိုမိုကောင်းမွန်မှုရှိအောင်
                              စီစဉ်ပေးခြင်း၊ ကျောင်းစည်းကမ်းနှင့်ပတ်သက်သည့် အကြောင်းအရာများ၊ ကြေညာချက်များကို မိဘများထံသို့
                              အချိန်နှင့်တစ်ပြေးညီ ရောက်ရှိအောင် ကြီးကြပ်ဆောင်ရွက်ပေးနေပါတယ်။`,
          pre: `ဒေါ်အေးသီရိခိုင် <br>
                              B.A (English) <br>
                              Certificate in Early Years Teaching Programme, Bell English (UK) <br>`,
          heading: 'heading10',
          collapse: 'collapse10',
          logos: [
            'safeguard.png'
          ]
        },
        {
          id: 'accordion11',
          title: "Senior English Teacher Message",
          img: 'daw-may-thazin-phoo.jpg',
          bio: `ကျွန်မသည် ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင် English သင်ကြားနေသော Senior ဆရာမ
                              တစ်ဦးဖြစ်ပါတယ်။
                              English တွင် English Language နှင့်
                              Literacy နှစ်ပိုင်းရှိသည့်အနက် ကျွန်မသည် Literacy အပိုင်းမှ Writing အပိုင်းကို တာဝန်ယူ
                              သင်ကြားပေးနေပါသည်။
                              ကျွန်မအနေနှင့် ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်းတွင် General Writing ရေးသားနည်း နှင့်
                              Academic
                              Writing
                              ရေးသားနည်း ကို အဓိအားပြုသင်ကြားနေပါသည်။
                              ထို့အပြင် သင်ကြားသင်ယူမှုဖြစ်စဉ် နှင့် ပတ်သက်နေသော အခြားသောလုပ်ငန်းဆောင်တာများလည်း
                              တာဝန်ယူထားပါတယ်ရှင့်။`,
          pre: `ဒေါ်မေသဇင်ဖူး <br>
                              M. A (English) <br>
                              Diploma in TESOL (LTTC, UK) <br>
                              Certificate in Applied ECCD (Brighter Future) <br>
                              Certificate in Online Learning Management (SIS ELT INSTITUTE)`,
          heading: 'heading11',
          collapse: 'collapse11',
        },
        {
          id: 'accordion12',
          title: "Senior Computing Teacher Message",
          img: 'daw-nang-aye-bo-kham.jpg',
          bio: `ကျွန်မကတော့ လက်ရှိ ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းမှာ အလယ်တန်းကလေးတွေကို Computing
                              ဘာသာရပ်ကိုသင်ကြားပေးနေတဲ့
                              ဒေါ်နန်းအေးဗိုလ်ခမ်းပဲဖြစ်ပါတယ်။ ကျွန်မကတော့ James Cook University, Australia (Singapore
                              Campus)
                              တွင်
                              Bachelor of Information Technology ဘွဲ ့ကို
                              ထူးချွန်စွာပြီးမြောက်အောင်မြင်ခဲ့ပါတယ်။ <br>
                              2017-18 အတွင်း JCUS Foundation Program ကျောင်းသူ/ကျောင်းသာများအတွက် Maths / Statistics ကို
                              campus
                              အတွင်း
                              Peer Tutor အဖြစ် သင်ကြားကူညီခဲ့ပါတယ်။
                              တစ်ဖက်တွင်လည်း IT ဘာသာရပ်ပေါင်း 24 ဘာသာကို လက်တွေ့ စာတွေ့ သင်ယူရင်း project ပေါင်း
                              မြောက်များစွာကို
                              ပြုလုပ်ပြီး MDP project အတွက် The Best Creative Video Award ကို ရရှိခဲ့ပါတယ်။`,
          pre: `ဒေါ်နန်းအေးဗိုလ်ခမ်း <br>
                              Bachelor of Information Technology <br>
                              James Cook University, Australia (Singapore Campus) <br>
                              B.A (English)`,
          heading: 'heading12',
          collapse: 'collapse12',
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher-image {
  display: inline-block;
  border: 1px;
  border-style: solid;
  float: left;
  width: 250px;
  max-height: 250px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;

  img {
    width: 250px;
    max-height: 320px;
    object-fit: cover;
  }
}

.teacher-info {
  min-height: 260px;
}

.teacher-content {
  text-align: justify;
  min-height: 250px;
}

.clear-fix {
  clear: both;
}

.plus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.minus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.accordion-button {
  text-transform: uppercase;
}

.accordion-button:not(.collapsed) .minus-icon {
  display: inline-block;
}

.accordion-button.collapsed .plus-icon {
  display: inline-block;
}

.accordion-button::after {
  display: none !important;
}

.accordion-body {
  padding: 0;
}

.avice_color {
  background: rgb(55, 0, 58);
  background: linear-gradient(355deg, rgba(55, 0, 58, 1) 0%, rgba(77, 0, 82, 1) 49%, rgba(113, 0, 120, 1) 100%);
}

.teacher-logo-title {
  text-transform: capitalize;
}

.teacher-logo {
  width: 150px;
  height: 150px;
}
</style>